@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Zen+Maru+Gothic:wght@300&display=swap);
/* IMPORT Google Fonts */

/* SET Font & Background color for entire Body */
body {
  font-family: 'Quicksand', sans-serif !important;
  background-color: rgb(26, 29, 29) !important;
  background-color: var(--background-color) !important;
}

/* ROOT COLOR VARIABLES */
:root {
  --primary-white: #ffffff;
  --secondary-color: #615c65;
  --primary-dark: rgba(255,255,255,0);
  --background-color: rgb(26, 29, 29);
  --neutral-color: #29342d;
  --black: #000000;
}

/* NAVBAR */

nav {
  z-index: 2;
}

nav a.nav-link {
  font-weight: 800;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav {
  background-color: transparent;
}

.navbar-nav > li > a {
  /* !important overrides bootstrap css for root color variables */
  color: #ffffff !important;
  color: var(--primary-white) !important;
  /* Spacing between navbar items */
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border-top: 0.1875rem solid rgba(255,255,255,0);
  border-top: 0.1875rem solid var(--primary-dark);
}

/* Navbar link hover effects */

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid rgba(255,255,255,0);
  border-top: 0.1875rem solid var(--primary-dark);
  font-weight: 800;
}

.navbar-nav > li > a:hover {
  color: #615c65 !important;
  color: var(--secondary-color) !important;
  border-top: 0.1875rem solid #615c65;
  border-top: 0.1875rem solid var(--secondary-color);
  font-weight: 800;
}

/* HEADER */

.header-wrapper {
  position: relative;
  background: url(/static/media/sky2.c3e69d0f.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
  color: #ffffff;
  color: var(--primary-white);
}

.main-info {
  display: flex;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}

.main-info h1 {
  font-weight: 1000;
  font-size: 5rem;
}

.header-text {
  margin-top: 15%;
}

.typed-text {
  font-size: 2rem;
  font-weight: 500;
  color: #ffffff;
  color: var(--primary-white);
}

/* React Particles */
header.particles {
  position: absolute !important;
  left: 0;
  top: 0;
  width:90%;
  height:90%
}

canvas.tsparticles-canvas-el {
  position: absolute !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 1 !important;
}

/* Media Query for Header Styling */
@media only screen and (max-width: 992px) {
  .main-info h1 {
    font-weight: 1000;
    font-size: 4rem;
  }
  
  .header-text {
    margin-top: 30%;
  }
  
  .typed-text {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
    color: var(--primary-white)
  }
}

@media only screen and (min-width: 1100px) {
  .portfolio-container-row {
    padding-left: 6rem;
    padding-right: 6rem
  }
}

/* ABOUT */

.about-img {
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 2px solid #615c65;
  border: 2px solid var(--secondary-color);
}

.col-lg-6 {
  color: #ffffff;
  color: var(--primary-white)
}

.about {
  font-weight: 800;
  font-size: 2rem;
  color: #615c65;
  color: var(--secondary-color);

}

.about-container {
  background-color: rgb(26, 29, 29);
  background-color: var(--background-color);
  padding-top: 5rem;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries for About Styling */
@media (max-width: 332px) {
  .about-img {
    width: 15rem;
    height: 15rem;
  } 
}

@media (max-width: 992px) {
  .row {
    text-align: center;
  }
  .about {
    text-align: center;
  }
  .about-text {
    text-align: center;
  }
}

/* SKILLS */

div.skills-wrapper {
  background-color: #615c65;
  background-color: var(--secondary-color);
  color: #ffffff;
  color: var(--primary-white);
  padding-bottom: 3rem;
}

.display-4 {
  margin: 2rem;
}

.display-4:hover {
  transform: scale(1.15);
  cursor: pointer;
}



/* RESUME */

a.download-button {
  color: #ffffff;
  color: var(--primary-white);
  text-decoration: none;
  font-size: 1.25rem;
  border-radius: 1rem;
  background-color: rgb(255,255,255,0.5);
  padding: 1rem;
  margin-top: 2rem;
  border: 2px solid #ffffff;
  border: 2px solid var(--primary-white);
}

a.download-button:hover {
  cursor: pointer;
  background-color: #29342d;
  background-color: var(--neutral-color);
  border: 2px solid #ffffff;
  border: 2px solid var(--primary-white);
}

h6 {
  color: #ffffff;
  color: var(--primary-white);
  padding-bottom: 2rem;
}

h1 {
  color: #ffffff;
  color: var(--primary-white);
  padding: 2rem
}

.download {
  text-align: center;
  margin-bottom: 2.5rem;
}

a.download-button:hover {
  cursor: pointer;
  color: #ffffff;
  color: var(--primary-white);
  background-color: #615c65;
  background-color: var(--secondary-color);
}

/* CONTACT */

.contact {
  background-color: #29342d;
  background-color: var(--neutral-color);
  padding: 3rem 0;
}

.contact h1 {
  color: #ffffff;
  color: var(--primary-white);
  padding: 0.5rem 0;
}

.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(255,255,255,0.55);
  resize: none;
  border: 0;
  border-bottom: 2px solid #ffffff;
  border-bottom: 2px solid var(--primary-white);
  border-radius: 1rem;
  color: #ffffff;
  color: var(--primary-white);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
}

div.container {
  text-align: center;
  justify-content: center;
}

.contact input {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button.contact-btn {
  background-color: rgb(255,255,255,0.5);
  color: #ffffff;
  color: var(--primary-white);
  font-weight: 500;
  border-radius: 1rem;
  margin: 1rem;
}

button.contact-btn:hover {
  color: #ffffff;
  color: var(--primary-white);
  background-color: #615c65;
  background-color: var(--secondary-color);
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: rgb(255,255,255,0.75);
  color: #000000;
  color: var(--black);
  font-size: 1.25rem;
  border-bottom: 2px solid #ffffff;
  border-bottom: 2px solid var(--primary-white);
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-size: 1.25rem;
}

input::placeholder, textarea::placeholder {
  font-size: 1.25rem;
}

.contact textarea {
  height: 9.5rem !important;
  margin: 1rem 0;
}

.error-message {
  color: #ffffff;
  color: var(--primary-white)
}

.success-message {
  font-weight: 900;
  color: #ffffff;
  color: var(--primary-white);
}

/* FOOTER */

.footer-container{
  color: #ffffff;
  color: var(--primary-white);
  text-align: center;
}

.icons {
  color: #ffffff;
  color: var(--primary-white);
  height: 3rem;
  width: 3rem;
  margin-right: 1.75rem;
  margin-left: 1.75rem;
  margin-bottom: 2rem;
}


.icons-footer {
  color: #ffffff;
  color: var(--primary-white);
  height: 3rem;
  width: 3rem;
  margin-right: 1.75rem;
  margin-left: 1.75rem;
  margin-bottom: 2rem;
}

.icons:hover {
  color: #615c65 !important;
  color: var(--secondary-color) !important;
}

.icons-footer:hover {
  color: #29342d !important;
  color: var(--neutral-color) !important;
}

footer {
  background-color: #615c65;
  background-color: var(--secondary-color);
}

/* Work */

.portfolio {
  background-color: #29342d;
  background-color: var(--neutral-color);
  padding-bottom: 5rem;

}

.portfolio-links {
  margin-top: 1rem;
}

svg.icons.portfolio-icon {
  margin-bottom: 0;
}

.portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
}

/* a.portfolio-icon:hover {
  color: var(--secondary-color)
} */

.justify-content-center {
  justify-content: center;
  align-self: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  background: #ffffff;
  background: var(--primary-white);
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #ffffff;
  color: var(--primary-white);
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: #ffffff;
  background: var(--primary-white);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  /* text-transform: uppercase; */
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  bottom: 10%;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap  {
  color: #ffffff;
  color: var(--primary-white);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

/* a:hover {
  color: var(--secondary-color)
} */

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  /* top: calc(50% - 48px); */
}

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  /* bottom: calc(50% - 50px); */
}

/* a.portfolio-icon {
  color: var(--secondary-color);
  opacity: 1
} */

/* Portfolio */
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary-white);
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffffff;
  background-color: var(--primary-white);
}

.portfolio-details .portfolio-info {
  padding: 15px;
  box-shadow: 0px 0 30px rgba(44, 73, 100, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 10px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.desc {
  color: #ffffff;
  color: var(--primary-white);
  padding: 1rem
}

